import React from 'react'
import {Flex, Link, Text} from '@chakra-ui/react'

import {TERMS_OF_SERVICE_URL, PRIVACY_POLICY_URL} from '../../constants'
import copy from './copy.json'

export const Legalese: React.FC<React.PropsWithChildren> = () => {
  return (
    <Flex flexDirection='column' textAlign='center' color='#333'>
      <Text fontSize={['0.75rem', '1rem']}>
        By signing in, you agree to Campminder&apos;s&nbsp;
      </Text>
      <Text>
        <Link
          href={TERMS_OF_SERVICE_URL}
          isExternal
          style={{textDecoration: 'underline'}}>
          {copy.links.tos}
        </Link>
        &nbsp;and&nbsp;
        <Link
          href={PRIVACY_POLICY_URL}
          isExternal
          style={{textDecoration: 'underline'}}>
          {copy.links.pp}
        </Link>
      </Text>
    </Flex>
  )
}
